import React, { FunctionComponent } from "react"

export const AutoArchivePrivacyPolicy: FunctionComponent = () => {
  return (
    <main>
      <h1>Privacy Policy</h1>
      <p>
        While this app has considerable access to a user's inbox, this app only
        reads what is strictly necessary to perform its functions, which is to
        inspect the labels of emails inside a user's inbox. None other data is
        read or stored outside of this purpose, and will never be in the future.
      </p>
    </main>
  )
}

export default AutoArchivePrivacyPolicy
